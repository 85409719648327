import { cloneDeep, get, merge } from 'lodash';
import initConfig, { ConfType, ConfItemType } from '../config';
import { IConfiguration, IModels, MenuItemType } from '../types/types';
import overrides from '.';

export default class OverrideService {
  static modules: Array<string> = [];
  static menuItems: Array<MenuItemType> = [];
  static models: IModels = {};

  static configuration: IConfiguration = {
    config: initConfig,
    overrides,
  };

  static storeConfiguration(configuration: any) {
    this.configuration = merge(this.configuration, configuration);
    this.setConfig(configuration.config);
  }

  static getConfiguration() {
    return this.configuration ? this.configuration : null;
  }

  static setModels(models?: IModels) {
    if (models) {
      this.models = merge(this.models, models);
    }
  }

  static getModels() {
    return this.models;
  }

  static getOverrides() {
    return this.configuration && this.configuration.overrides
      ? this.configuration.overrides
      : {};
  }

  static getConfig(): ConfType {
    return this.configuration && this.configuration.config
      ? this.configuration.config
      : {};
  }

  static setConfig(config: Partial<ConfItemType>) {
    this.configuration.config = {
      ...this.configuration.config,
      ...config,
    };
  }

  static getRoutes() {
    return get(this.configuration, 'routes', []);
  }

  static getNavigation() {
    return get(this.configuration, 'navigation', {
      auth: {},
    });
  }

  static getStyles() {
    return this.configuration && this.configuration.styles
      ? this.configuration.styles
      : null;
  }

  static setStyles(styles: any) {
    this.configuration.styles = styles;
  }

  static getModules(): Array<string> {
    return this.modules;
  }

  static setModules(modules: Array<string>) {
    this.modules = [...modules];
  }

  static setMenuItems(menuItems: Array<MenuItemType>) {
    this.menuItems = [...menuItems];
  }

  // static getMenuItems(): Array<IMenuItem> {
  static getMenuItems(): Array<MenuItemType> {
    return this.menuItems;
  }

  // Filter menu items base on user permissions
  static getFilterMenuItems(permissions: {
    allow: string[];
    deny: string[];
  }): Array<MenuItemType> {
    // const { allow, deny } = permissions;
    // const newMenuItems = [
    //   ...this.menuItems.filter((mi) => allow.includes(mi.permission)),
    // ];
    // return newMenuItems;

    // Allow modules to override submenu of other modules (fee-module, users-module)
    let mergedArray = cloneDeep(this.menuItems);
    mergedArray = mergedArray.reduce(
      (acc: MenuItemType[], current: MenuItemType) => {
        const existingIndex = acc.findIndex(
          (item: MenuItemType) => item.name === current.name
        );
        if (existingIndex !== -1 && current.children) {
          acc[existingIndex].children?.push(...current.children);
        } else {
          acc.push(current);
        }

        return acc;
      },
      []
    );

    return mergedArray;
  }
}
