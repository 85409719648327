import { PaymentDevice } from '../../modules/payment-devices/redux/paymentDevices.api.types';
import { formatDate } from '../utils/DatesHelper';

// Consider if this should be placed in different object like config?
const overrides = {
  'card-details-summary': (card: PaymentDevice) => [
    { label: 'Type', value: card.type },
    { label: 'Expires', value: formatDate(card.expiryDate, 'MM/YY') },
    { label: 'PAN', value: card.pan },
    { label: '3DS mobile', value: card.user.mobile },
    { label: 'Account', value: `${card.account.currency} Account` },
  ],
};

export default overrides;
