import NoProfilePicture from '../../assets/no_profile_image.png';
import { PaymentDeviceStatus } from '../../modules/payment-devices/redux/paymentDevices.types';

export type CardAction =
  | 'change-status'
  | 'update-3ds'
  | 'clear-3ds'
  | 'load-card'
  | 'unload-card'
  | 'lock';

type ExportReportsType = 'users' | 'accounts' | 'transactions' | 'cards';

export type ConfType = {
  notesCharLength?: number;
  appName?: string;
  routesBlackList?: string[];
  profileDefaultImage?: any;
  firstDayOfWeekMonday?: boolean;
  cardActions?: Array<CardAction>;
  paymentDeviceStatuses?: Array<PaymentDeviceStatus>;
  multipleTabsSession?: boolean;
  exportReports?: ExportReportsType[];
};

export type ConfItemType = {
  [key in keyof ConfType]?: ConfType[key];
};

const config: ConfType = {
  appName: 'Bankvault 2.0',
  routesBlackList: [],
  profileDefaultImage: NoProfilePicture,
  firstDayOfWeekMonday: true,
  cardActions: [
    'lock',
    'change-status',
    'update-3ds',
    'clear-3ds',
    'load-card',
    'unload-card',
  ],
  exportReports: ['users', 'transactions'],
};

export default config;
