import React from 'react';
import ReactDOM from 'react-dom/client';
import 'font-awesome/css/font-awesome.min.css';
import './index.css';
// import reportWebVitals from './reportWebVitals';
import OverrideService from './@launchpad/core/overrides/OverrideService';
import styles from './@baseProject/styles/index';

const Main = React.lazy(() => import('./@baseProject'));
OverrideService.setStyles(styles);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// root.render(
//   <React.StrictMode>
//     <Main />
//   </React.StrictMode>
// );
root.render(<Main />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
