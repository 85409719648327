import moment, { MomentInput } from 'moment';
import { DayValue } from '@hassanmojab/react-modern-calendar-datepicker';
// import i18n from '../../i18n';

/**
 * Get date in given format
 * @param dateValue
 * @param format
 */
export function formatDate(dateValue: MomentInput, format = 'DD/MM/Y') {
  return moment(dateValue).format(format);
}

/**
 * Validate datestring
 * @param dateString DD/MM/YYYY
 * @returns boolean
 */
export function validateDate(dateString: string) {
  const date = dateString.split('/');
  if (date.length === 3) {
    if (date[0].length !== 2 || date[1].length !== 2 || date[2].length !== 4) {
      return false;
    }
    const d = new Date(`${date[2]}-${date[1]}-${date[0]}`);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(d.getTime())) {
      return false;
    }
    return true;
  }
  return false;
}

// Format date and return specific value if date is today or yesterday
export function formatSectionDate(dateValue: MomentInput, format = 'DD/MM/Y') {
  const date = new Date();
  // today
  if (formatDate(dateValue, format) === formatDate(date, format)) {
    return 'Today';
  }
  // yesterday
  date.setDate(date.getDate() - 1);
  if (formatDate(dateValue, format) === formatDate(date, format)) {
    return 'Yesterday';
  }
  return formatDate(dateValue, format);
}

// Get date from DatePicker as string and return in YYYY-MM-DD format
export function formatDateFromDatePickerString(date: string) {
  let newDate = date;
  if (date.lastIndexOf('.') === date.length - 1) {
    newDate = date.substring(0, date.length - 1);
  }
  return newDate.split('.').reverse().join('-');
}

// Get date from DatePicker and return in YYYY-MM-DD format
export function formatDateFromDatePicker(date: DayValue | string) {
  if (date === undefined || date === null) {
    return undefined;
  }
  if (typeof date === 'string') {
    return date;
  }

  return `${date.year}-${date.month < 10 ? `0${date.month}` : date.month}-${
    date.day < 10 ? `0${date.day}` : date.day
  }`;
}

export default {
  formatDate,
  validateDate,
  formatSectionDate,
  formatDateFromDatePickerString,
  formatDateFromDatePicker,
};
